import React, { useState } from 'react';
import { cloneDeep, flatten, isEmpty, parseInt } from 'lodash';
import { DateTime } from 'luxon';
import { TextValidator } from 'react-material-ui-form-validator';
import {
  BoostPay,
  DelayedDeliveryIncentive,
  DelayedDeliveryPay,
  DelayedDeliveryType,
  DppAdjustmentRuleType,
  GenericPayRateMetadata,
  GenericPayRateType,
  H3PayRate,
  ListBasedPayRate,
  OrderAttributePay,
  PayRate,
  PayRateKind,
  PayRateSchedule,
  PayRateScheduleSpan,
  PayRateStatus,
  PayRateToEdit,
  PayRateType,
  PriorityType,
  ReimbursementRate,
  ScheduledRateType,
  TripIncentive,
  VariableBasePayCheck,
  VendorType,
} from '../interfaces/PayRate';
import {
  convertDateByTimeZone,
  convertLocalToDeliveryZone,
  easternTimezone,
  handleEndDate,
  parseDate,
} from './Dates';
import { Location } from '../interfaces/Location';
import {
  getCurrencySymbolFromLocation,
  getTimeZoneByLocationId,
} from './Locations';
import {
  boostPayRateType,
  payRateScheduleSpanRanges,
  payRateStatusPriorities,
} from '../variables/PayRate';
import {
  isPositiveErrorMessage,
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from './Constants';
import { isNumber } from './Misc';
import { getCurrentWorkweekInTimezone } from './Workweek';
import StringField from '../components/StringField';
import { QueryParameter } from '../interfaces/Url';

export const parsePayRateFromResponse = (
  actualPayRateType: PayRateType,
  receivedPayRate: any,
) => {
  return {
    ...actualPayRateType.fields.reduce(
      (acc, field) => field.responseParser(acc, receivedPayRate),
      actualPayRateType.template,
    ),
    id: receivedPayRate.id,
    locationID: receivedPayRate.location_id,
    startAt: new Date(receivedPayRate.start_at),
    endAt: handleEndDate(receivedPayRate.end_at),
  };
};

export const extractPossiblePayType = (
  payRateType: PayRateType,
): string | undefined => {
  if (payRateType.kind === PayRateKind.WAIT_PAY) {
    return `${ScheduledRateType[payRateType.kind]}`;
  }
  if (payRateType.kind === PayRateKind.BATCH_NORMALIZATION_RULES) {
    return DppAdjustmentRuleType[payRateType.kind];
  }
  if (
    payRateType.kind === PayRateKind.COMMISSION ||
    payRateType.kind === PayRateKind.SUBSIDY ||
    payRateType.kind === PayRateKind.ENGAGED ||
    payRateType.kind === PayRateKind.BOOST_PAY ||
    payRateType.kind === PayRateKind.HAZARD_PAY ||
    payRateType.kind === PayRateKind.TOBACCO_BONUS ||
    payRateType.kind === PayRateKind.TIER2_DELIVERIES ||
    payRateType.kind === PayRateKind.ALCOHOL_PAY ||
    payRateType.kind === PayRateKind.FIRST_SHIFT_BONUS ||
    payRateType.kind === PayRateKind.OVERTIME_PAY ||
    payRateType.kind === PayRateKind.WEEKLY_BONUS ||
    payRateType.kind === PayRateKind.RETURN_COC ||
    payRateType.kind === PayRateKind.DELAYED_DELIVERY ||
    payRateType.kind === PayRateKind.ORDER_ATTRIBUTE ||
    payRateType.kind === PayRateKind.TRIP_ATTRIBUTE ||
    payRateType.kind === PayRateKind.REIMBURSEMENT_PAY
  ) {
    return GenericPayRateType[payRateType.kind];
  }

  return undefined;
};

export const parseNumberFieldFromResponse = <T extends PayRate>(
  payRate: T,
  responseData: any,
  fieldName: keyof T,
  responseFieldName: string,
  convertCurrency: boolean,
): T => ({
  ...payRate,
  // eslint-disable-next-line no-nested-ternary
  [fieldName]: convertCurrency
    ? responseData[responseFieldName] !== null
      ? responseData[responseFieldName] / 100
      : Number.NaN
    : responseData[responseFieldName] !== null
    ? responseData[responseFieldName]
    : Number.NaN,
});

export const parsePayRateMetadataFromResponse = (
  responseData: any,
): GenericPayRateMetadata => ({
  legacyID: responseData?.metadata?.legacy_id,
});

export const payRateMetadataOutputTransformer = (
  metadata: GenericPayRateMetadata,
): any => ({
  legacy_id: metadata.legacyID,
});

export const delayedDeliveryMetadataOutputTransformer = (
  payRate: DelayedDeliveryPay,
) => {
  const incentives = [];
  if (payRate.standardAmount > 0) {
    incentives.push({
      type: DelayedDeliveryType.STANDARD,
      delay_minutes: payRate.standardDelayMinutes,
      offer_denials: payRate.standardOfferDenials,
      amount: Math.round(payRate.standardAmount * 100),
    });
  }
  if (payRate.fam20Amount > 0) {
    incentives.push({
      type: DelayedDeliveryType.FAM_20,
      delay_minutes: payRate.fam20DelayMinutes,
      amount: Math.round(payRate.fam20Amount * 100),
    });
  }
  if (payRate.starbucksAmount > 0) {
    incentives.push({
      type: DelayedDeliveryType.STARBUCKS,
      delay_minutes: payRate.starbucksDelayMinutes,
      amount: Math.round(payRate.starbucksAmount * 100),
    });
  }

  return { incentives };
};

export const tripIncentiveMetadataOutputTransformer = (
  payRate: TripIncentive,
) => {
  return {
    incentive_per_distance_in_meters: payRate.incentivePerDistanceInMiles.map(
      (a) => {
        return { amount: a.amount * 100, threshold: a.threshold * 1609.34 };
      },
    ),
    incentive_per_duration_in_seconds:
      payRate.incentivePerDurationInMinutes.map((a) => {
        return { amount: a.amount * 100, threshold: a.threshold * 60 };
      }),
  };
};

export const orderAttributeMetadataOutputTransformer = (
  payRate: OrderAttributePay,
) => {
  const priority = [];
  if (isValidRateAmount(payRate.priorityAmount)) {
    priority.push({
      name: PriorityType.PRIORITY,
      amount: Math.round(payRate.priorityAmount * 100),
    });
  }
  if (isValidRateAmount(payRate.fam20PriorityAmount)) {
    priority.push({
      name: PriorityType.FAM20,
      amount: Math.round(payRate.fam20PriorityAmount * 100),
    });
  }

  const vendors = [];
  if (isValidRateAmount(payRate.gopuffAmount)) {
    vendors.push({
      name: VendorType.GOPUFF,
      amount: Math.round(payRate.gopuffAmount * 100),
    });
  }
  if (isValidRateAmount(payRate.shopifyAmount)) {
    vendors.push({
      name: VendorType.SHOPIFY,
      amount: Math.round(payRate.shopifyAmount * 100),
    });
  }
  if (isValidRateAmount(payRate.uberEatsAmount)) {
    vendors.push({
      name: VendorType.UBER_EATS,
      amount: Math.round(payRate.uberEatsAmount * 100),
    });
  }
  if (isValidRateAmount(payRate.doordashAmount)) {
    vendors.push({
      name: VendorType.DOORDASH,
      amount: Math.round(payRate.doordashAmount * 100),
    });
  }

  return {
    priority,
    vendors,
    newbie: Math.round(payRate.newbieAmount * 100),
    starbucks: Math.round(payRate.starbucksAmount * 100),
    customer_id: payRate.customerID
      .filter((a) => isValidRateAmount(a.amount) && a.list.length > 0)
      .map((c: ListBasedPayRate) => {
        return { amount: Math.round(c.amount * 100), list: c.list };
      }),
    h3s: payRate.h3s
      .filter((a) => a.amount !== 0 && a.hexID !== '')
      .map((c: H3PayRate) => {
        return {
          amount: Math.round(c.amount * 100),
          resolution: c.resolution,
          hex_id: c.hexID,
        };
      }),
    handshake: Math.round(payRate.handshakeAmount * 100),
  };
};

export const arePayRateScheduleSpansIntersecting = (
  a: PayRateScheduleSpan,
  b: PayRateScheduleSpan,
): boolean => {
  const startTimeA = 24 * a.dayStart + a.timeStart;
  const endTimeA = 24 * a.dayEnd + a.timeEnd;

  const startTimeB = 24 * b.dayStart + b.timeStart;
  const endTimeB = 24 * b.dayEnd + b.timeEnd;

  return startTimeA < endTimeB && endTimeA > startTimeB;
};

export const mergeAdjacentEqualRateSpans = (
  spans: PayRateScheduleSpan[],
): PayRateScheduleSpan[] => {
  const sortedSpans = cloneDeep(spans);
  sortedSpans.sort(
    (a, b) => a.dayStart - b.dayStart || a.timeStart - b.timeStart,
  );

  const mergedSpans: PayRateScheduleSpan[] = [];

  let mergedSpan = cloneDeep(sortedSpans[0]);

  sortedSpans.forEach((span: PayRateScheduleSpan) => {
    if (span.rate === mergedSpan.rate) {
      mergedSpan.dayEnd = span.dayEnd;
      mergedSpan.timeEnd = span.timeEnd;
    } else {
      mergedSpans.push(cloneDeep(mergedSpan));
      mergedSpan = cloneDeep(span);
    }
  });
  mergedSpans.push(mergedSpan);

  return mergedSpans;
};

export const parsePayRateScheduleSpansFromResponse = (
  payRateSchedule: PayRateSchedule,
  responseData: any,
): PayRateSchedule => {
  const spans = flatten(
    [0, 1, 2, 3, 4, 5, 6].map((weekDay) =>
      payRateScheduleSpanRanges.map(
        (range): PayRateScheduleSpan => ({
          dayStart: weekDay,
          dayEnd: weekDay,
          timeStart: range.timeStart,
          timeEnd: range.timeEnd,
        }),
      ),
    ),
  );

  (responseData.spans ?? []).forEach((receivedSpan: any) => {
    const span: PayRateScheduleSpan = {
      rate: receivedSpan.rate,
      dayStart: receivedSpan.day_start,
      dayEnd: receivedSpan.day_end,
      timeStart: receivedSpan.time_start,
      timeEnd: receivedSpan.time_end,
    };

    for (let i = 0; i < spans.length; i += 1) {
      let rate = span.rate ?? 0;
      rate /= 60;
      rate = Math.round(Math.round(rate * 100) / 100);
      if (arePayRateScheduleSpansIntersecting(span, spans[i])) {
        spans[i].rate = rate / 100;
      }
    }
  });

  return {
    ...payRateSchedule,
    spans,
  };
};

export const parseDelayedDeliveryIncentiveFromResponse = (
  responseData: any,
  type: DelayedDeliveryType,
): DelayedDeliveryIncentive | undefined => {
  const metadata = responseData.metadata ?? {};
  const incentives = metadata.incentives ?? [];
  const rawIncentive = incentives.find(
    (incentive: any) => incentive.type === type && incentive.amount > 0,
  );

  if (!rawIncentive) {
    return undefined;
  }

  return {
    delayMinutes: rawIncentive.delay_minutes ?? 0,
    offerDenials: rawIncentive.offer_denials ?? 0,
    amount: rawIncentive.amount ?? 0,
  };
};

export const parseOrderVendorFromResponse = (
  responseData: any,
  name: VendorType,
): number | undefined => {
  const metadata = responseData.metadata ?? {};
  const incentives = metadata.vendors ?? [];
  const rawIncentive = incentives.find(
    (incentive: any) => incentive.name === name && incentive.amount !== 0,
  );

  if (!rawIncentive) {
    return undefined;
  }

  return rawIncentive.amount;
};

export const parsePriorityFromResponse = (
  responseData: any,
  name: PriorityType,
): number | undefined => {
  const metadata = responseData.metadata ?? {};
  const incentives = metadata.priority ?? [];
  const rawIncentive = incentives.find(
    (incentive: any) => incentive.name === name && incentive.amount !== 0,
  );

  if (!rawIncentive) {
    return undefined;
  }

  return rawIncentive.amount;
};

export const extractNumberField = <T extends PayRate>(
  payRate: PayRate,
  fieldName: keyof T,
): JSX.Element => {
  const amount = (payRate as T)[fieldName] as unknown as number;
  return (
    <StringField
      value={amount ? amount.toString() : 'N/A'}
      disabled={payRate.status === PayRateStatus.EXPIRED}
    />
  );
};

export const extractPaymentField = <T extends PayRate>(
  payRate: PayRate,
  fieldName: keyof T,
  location: Location | null,
): JSX.Element => {
  const amount = (payRate as T)[fieldName] as unknown as number;
  return (
    <StringField
      value={
        amount
          ? `${getCurrencySymbolFromLocation(location)}${amount.toFixed(2)}`
          : 'N/A'
      }
      disabled={payRate.status === PayRateStatus.EXPIRED}
    />
  );
};

export const extractMultiplierField = <T extends PayRate>(
  payRate: PayRate,
  fieldName: keyof T,
): JSX.Element => {
  const multiplier = (payRate as T)[fieldName] as unknown as number;
  return (
    <StringField
      value={multiplier ? `${multiplier}x` : 'N/A'}
      disabled={payRate.status === PayRateStatus.EXPIRED}
    />
  );
};
export const defaultOutputTransformer = (
  payRateToTransform: any,
  timeZone: string | null,
  isEditing: boolean,
  time?: string,
): any => ({
  location_id: payRateToTransform.locationID.toString(),
  start_at: isEditing
    ? payRateToTransform.startAt
    : convertDateByTimeZone(
        timeZone,
        parseDate(payRateToTransform.startAt.toISOString()),
        time,
      ),
});

export const variableBasePayTableRequiredForPayRateKind = (
  payRateKind: PayRateKind,
  allPayRateTypes: PayRateType[],
): boolean => {
  if (payRateKind === PayRateKind.ALL_KINDS) {
    return true;
  }

  return (
    allPayRateTypes.filter(
      (payRateType) =>
        payRateType.kind === payRateKind &&
        payRateType.variableBasePayCheck !== VariableBasePayCheck.NONE,
    ).length > 0
  );
};

export const payRateStatusFromTimeRange = (
  startAt: Date,
  endAt?: Date,
): PayRateStatus => {
  const timeNow = new Date();
  if (new Date(startAt) > timeNow) {
    return PayRateStatus.FUTURE;
  }

  return !endAt || new Date(endAt) > timeNow
    ? PayRateStatus.ACTIVE
    : PayRateStatus.EXPIRED;
};

export const payRateStatusOrderPriority = (status: PayRateStatus): number => {
  switch (status) {
    case PayRateStatus.FUTURE:
      return 1;
    case PayRateStatus.ACTIVE:
      return 2;
    case PayRateStatus.EXPIRED:
      return 3;
    default:
      return 999;
  }
};

export const listedPayRateComparator = (a: PayRate, b: PayRate): number => {
  if (payRateStatusPriorities[a.status] > payRateStatusPriorities[b.status]) {
    return -1;
  }
  if (payRateStatusPriorities[a.status] < payRateStatusPriorities[b.status]) {
    return 1;
  }
  return parseInt(a.locationID) < parseInt(b.locationID) ? -1 : 1;
};

export const multiLocationBoostPayTransformer = (
  selectedLocationIDs: string[],
  locations: Location[],
  boostPayModel: BoostPay,
): BoostPay[] =>
  selectedLocationIDs.map((locationID) => {
    const timeZone =
      getTimeZoneByLocationId(locations, parseInt(locationID, 10)) || 'UTC';
    const utcStartTime = DateTime.fromJSDate(boostPayModel.startTime).setZone(
      'UTC',
    );
    const utcEndTime = DateTime.fromJSDate(boostPayModel.endTime).setZone(
      'UTC',
    );
    const utcStartAt = DateTime.fromJSDate(boostPayModel.startAt).setZone(
      'UTC',
    );
    const utcEndAt = DateTime.fromJSDate(
      boostPayModel.endAt.time || new Date(),
    ).setZone('UTC');
    return boostPayRateType.outputTransformer(
      {
        ...boostPayModel,
        startTime: DateTime.fromJSDate(boostPayModel.startTime)
          .setZone(timeZone)
          .set({
            hour: utcStartTime.hour,
            minute: utcStartTime.minute,
            second: 0,
            millisecond: 0,
          })
          .toJSDate(),
        endTime: DateTime.fromJSDate(boostPayModel.endTime)
          .setZone(timeZone)
          .set({
            hour: utcEndTime.hour,
            minute: utcEndTime.minute,
            second: 0,
            millisecond: 0,
          })
          .toJSDate(),
        startAt: convertLocalToDeliveryZone(timeZone, utcStartAt).toJSDate(),
        endAt: {
          time: convertLocalToDeliveryZone(timeZone, utcEndAt).toJSDate(),
          valid: true,
        },
        locationID,
      },
      timeZone,
      false,
    );
  });

export const generateNumberInput = <T extends PayRate>(
  required: boolean,
  acceptFloat: boolean,
  currentState: PayRate,
  setter: (payRate: PayRate) => void,
  fieldName: keyof T,
  disabled: boolean,
  placeholder?: string,
  minimumValue?: number,
  testId?: string,
  helperTextComponent?: JSX.Element,
  nonZero?: boolean,
  hidden?: boolean,
  negativeAllowed?: boolean,
): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');
  const validators = [];
  const errorMessages = [];

  if (!negativeAllowed) {
    validators.push('isPositive');
    errorMessages.push(isPositiveErrorMessage);
  }

  if (acceptFloat) {
    validators.push(`matchRegexp:${max2DecimalsRegexp}`);
    errorMessages.push(max2DecimalErrorMessage);
  } else {
    validators.push('isNumber');
    errorMessages.push('Order must be integer');
  }

  if (minimumValue) {
    validators.push(`minNumber:${minimumValue}`);
    errorMessages.push(`Minimum value: ${minimumValue}`);
  }

  if (required) {
    validators.push('required');
    errorMessages.push(requiredErrorMessage);
  }

  if (nonZero) {
    validators.push('nonZero');
    errorMessages.push(isPositiveErrorMessage);
  }

  const handleChange = (e: any) => {
    setter({
      ...currentState,
      [fieldName]: acceptFloat
        ? parseFloat(e.target.value)
        : parseInt(e.target.value, 10),
    });
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }
    const value = (currentState as T)[fieldName] as unknown as number;
    if (isNumber(value)) {
      return acceptFloat ? value.toFixed(2) : value.toString();
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return hidden ? (
    <></>
  ) : (
    <TextValidator
      label={placeholder === '' ? '' : placeholder || fieldName}
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      validators={validators}
      errorMessages={errorMessages}
      name={fieldName as string}
      disabled={disabled}
      data-testid={testId}
      helperText={helperTextComponent}
    />
  );
};

export const getEndOfSubsidyLockTime = (timezone: string): Date => {
  const currentWorkweekStartInEastern =
    getCurrentWorkweekInTimezone(easternTimezone);
  const easternWednesday5pm = DateTime.fromJSDate(
    currentWorkweekStartInEastern,
  ).plus({ day: 2, hour: 12 });

  const currentWorkweekStartInLocation = getCurrentWorkweekInTimezone(timezone);

  if (DateTime.now() > easternWednesday5pm) {
    return DateTime.fromJSDate(currentWorkweekStartInLocation)
      .plus({
        week: 2,
        second: -1,
      })
      .toJSDate();
  }

  return DateTime.fromJSDate(currentWorkweekStartInLocation)
    .plus({
      week: 1,
      second: -1,
    })
    .toJSDate();
};

export const getSetRuleTitle = (
  payRateToEdit: PayRateToEdit | null,
  selectedRateType: PayRateType,
  editableStatusList: PayRateStatus[],
): string => {
  if (!payRateToEdit) {
    return `Set ${selectedRateType.name}`;
  }

  return editableStatusList.includes(payRateToEdit.payRate.status)
    ? `Edit ${selectedRateType.name}`
    : `View ${selectedRateType.name}`;
};

export const isValidRateAmount = (amount: number): boolean => {
  return (
    !Number.isNaN(amount) &&
    amount !== null &&
    amount !== undefined &&
    amount !== 0
  );
};

export const isValidRateString = (s: string): boolean => {
  return s !== null && s !== undefined && s !== '';
};

export const getReimbursementRateName = (
  reimbursementRate: ReimbursementRate,
): string => {
  switch (reimbursementRate) {
    case ReimbursementRate.PER_HOUR:
      return 'Per Hour';
    case ReimbursementRate.PER_TRIP:
      return 'Per Trip';
    case ReimbursementRate.PER_ORDER:
      return 'Per Order';
    default:
      return reimbursementRate;
  }
};

export const getPayRateFilterQueryParams = (
  filteredLocationIDs: string[],
  selectedPayRateKind: PayRateKind,
  activePayRatesChecked: boolean,
): QueryParameter[] => {
  const params: QueryParameter[] = [];
  if (activePayRatesChecked) {
    params.push({
      key: 'all-active',
      value: '1',
    });
  } else if (!isEmpty(filteredLocationIDs)) {
    params.push({
      key: 'location-ids',
      value: filteredLocationIDs.join(','),
    });
  }

  if (selectedPayRateKind !== PayRateKind.ALL_KINDS) {
    params.push({
      key: 'pay-rate-kind',
      value: selectedPayRateKind,
    });
  }

  return params;
};
