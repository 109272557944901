import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Divider, Grid, IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { TextValidator } from 'react-material-ui-form-validator';
import DeleteIcon from '@mui/icons-material/Delete';
import { sharedColors } from '../../../utilities/Styles';
import {
  PayRate,
  PayRateFormProps,
  ThresholdBasedPayRate,
  TripIncentive,
} from '../../../interfaces/PayRate';
import {
  max2DecimalErrorMessage,
  max2DecimalsRegexp,
  requiredErrorMessage,
} from '../../../utilities/Constants';
import { isNumber } from '../../../utilities/Misc';

interface PaySettingAmountInputProps {
  value: number;
  onChange: (newValue: number) => void;
  disabled: boolean;
  title: string;
}

const PaySettingAmountInput = (props: PaySettingAmountInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const validators = [`matchRegexp:${max2DecimalsRegexp}`, 'required'];
  const errorMessages = [max2DecimalErrorMessage, requiredErrorMessage];

  const handleChange = (e: any) => {
    props.onChange(parseFloat(e.target.value));
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }
    if (isNumber(props.value)) {
      return props.value.toFixed(2);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextValidator
      label={props.title}
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      validators={validators}
      errorMessages={errorMessages}
      name={props.title}
      disabled={props.disabled}
    />
  );
};

interface PaySettingRowProps {
  currentSetting: ThresholdBasedPayRate;
  onChange: (newSetting: ThresholdBasedPayRate) => void;
  thresholdTitle: string;
  onDelete?: () => void;
  disabled: boolean;
}

const PaySettingRow = (props: PaySettingRowProps) => {
  const classes = useStyles();

  const handleChangeAmount = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      amount: newAmount,
    });
  };

  const handleChangeThreshold = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      threshold: newAmount,
    });
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={4} className={classes.gridItem}>
        <PaySettingAmountInput
          title={props.thresholdTitle}
          value={props.currentSetting.threshold}
          onChange={handleChangeThreshold}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        <PaySettingAmountInput
          title={'Amount'}
          value={props.currentSetting.amount}
          onChange={handleChangeAmount}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        <IconButton
          size='small'
          color='error'
          disabled={!props.onDelete || props.disabled}
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const TripIncentiveForm = (props: PayRateFormProps) => {
  const classes = useStyles();

  const tripIncentive = cloneDeep(props.currentState as TripIncentive);

  if (tripIncentive.incentivePerDurationInMinutes.length === 0) {
    tripIncentive.incentivePerDurationInMinutes = [{ threshold: 0, amount: 0 }];
  }
  if (tripIncentive.incentivePerDistanceInMiles.length === 0) {
    tripIncentive.incentivePerDistanceInMiles = [{ threshold: 0, amount: 0 }];
  }

  const handleDistanceChangeTier = (
    newSetting: ThresholdBasedPayRate,
    i: number,
  ) => {
    const newTiers = cloneDeep(tripIncentive.incentivePerDistanceInMiles);
    newTiers[i] = cloneDeep(newSetting);
    props.setter({
      ...tripIncentive,
      incentivePerDistanceInMiles: newTiers,
    } as TripIncentive as PayRate);
  };

  const handleDurationChangeTier = (
    newSetting: ThresholdBasedPayRate,
    i: number,
  ) => {
    const newTiers = cloneDeep(tripIncentive.incentivePerDurationInMinutes);
    newTiers[i] = cloneDeep(newSetting);
    props.setter({
      ...tripIncentive,
      incentivePerDurationInMinutes: newTiers,
    } as TripIncentive as PayRate);
  };

  const handleDeleteDistanceTier = (i: number) => {
    const newTiers = cloneDeep(tripIncentive.incentivePerDistanceInMiles);
    newTiers.splice(i, 1);
    props.setter({
      ...tripIncentive,
      incentivePerDistanceInMiles: newTiers,
    } as TripIncentive as PayRate);
  };

  const handleDeleteDurationTier = (i: number) => {
    const newTiers = cloneDeep(tripIncentive.incentivePerDurationInMinutes);
    newTiers.splice(i, 1);
    props.setter({
      ...tripIncentive,
      incentivePerDurationInMinutes: newTiers,
    } as TripIncentive as PayRate);
  };

  const handleNewDistanceTier = () => {
    props.setter({
      ...tripIncentive,
      incentivePerDistanceInMiles: [
        ...tripIncentive.incentivePerDistanceInMiles,
        { threshold: 0, amount: 0 },
      ],
    } as TripIncentive as PayRate);
  };

  const handleNewDurationTier = () => {
    props.setter({
      ...tripIncentive,
      incentivePerDurationInMinutes: [
        ...tripIncentive.incentivePerDurationInMinutes,
        { threshold: 0, amount: 0 },
      ],
    } as TripIncentive as PayRate);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Box component='div' className={classes.tiersContainer}>
        {tripIncentive.incentivePerDistanceInMiles.map((paySetting, i) => {
          return (
            <PaySettingRow
              currentSetting={paySetting}
              disabled={props.isDisabled}
              thresholdTitle={'Distance(miles)'}
              onChange={(newSetting) => handleDistanceChangeTier(newSetting, i)}
              onDelete={
                tripIncentive.incentivePerDistanceInMiles.length > 1
                  ? () => handleDeleteDistanceTier(i)
                  : undefined
              }
            />
          );
        })}
        {
          <Button
            disabled={props.isDisabled}
            onClick={handleNewDistanceTier}
            className={classes.newTierButton}
          >
            + New Distance Rule
          </Button>
        }
        <Divider />
        {tripIncentive.incentivePerDurationInMinutes.map((paySetting, i) => {
          return (
            <PaySettingRow
              currentSetting={paySetting}
              disabled={props.isDisabled}
              thresholdTitle={'Duration(minutes)'}
              onChange={(newSetting) => handleDurationChangeTier(newSetting, i)}
              onDelete={
                tripIncentive.incentivePerDurationInMinutes.length > 1
                  ? () => handleDeleteDurationTier(i)
                  : undefined
              }
            />
          );
        })}
        {
          <Button
            disabled={props.isDisabled}
            onClick={handleNewDurationTier}
            className={classes.newTierButton}
          >
            + New Duration Rule
          </Button>
        }
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexGrow: 1,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dropOffsDropdown: {
      display: 'flex',
      flexGrow: 1,
    },
    dropdownLabel: {
      backgroundColor: sharedColors.white,
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    tiersContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    newTierButton: {
      textTransform: 'none',
      width: 'fit-content',
    },
  }),
);
