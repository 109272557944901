import React, { useState } from 'react';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { locations as locationsAtom } from '../../atoms/Location';
import { payRateToEdit as payRateToEditAtom } from '../../atoms/PayRate';
import { formatDateTime } from '../../utilities/Dates';
import { multiClass } from '../../utilities/Extensions';
import {
  PayRate,
  PayRateKind,
  PayRateStatus,
  PayRateType,
} from '../../interfaces/PayRate';
import { sharedColors, useSharedStyles } from '../../utilities/Styles';
import { getTimeZoneByLocationId } from '../../utilities/Locations';
import StringField from '../StringField';

export const payRateTableHeaderTestId = 'PayRateTableHeader';
export const payRateStatusActive = 'ACTIVE';
export const payRateStatusExpired = 'EXPIRED';
export const payRateStatusFuture = 'FUTURE';

interface PayRateTypeChipProps {
  type: PayRateType;
  disabled: boolean;
}

const PayRateTypeChip = (props: PayRateTypeChipProps) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.typeChip}
      disabled={props.disabled}
      label={
        <Typography className={classes.typeChipText}>
          {props.type.shortName}
        </Typography>
      }
    />
  );
};

interface PayRateTableProps {
  payRateType: PayRateType;
  rows: PayRate[];
  editable: boolean;
}

const PayRateTable = (props: PayRateTableProps): JSX.Element => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles().classes;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const setPayRateToEdit = useSetRecoilState(payRateToEditAtom);
  const locations = useRecoilValue(locationsAtom);

  const handleClickRow = (row: PayRate) => {
    setPayRateToEdit({ payRate: row, payRateType: props.payRateType });
  };

  const editableStatusList =
    props.payRateType.kind !== PayRateKind.WAIT_PAY &&
    props.payRateType.kind !== PayRateKind.SUBSIDY &&
    props.payRateType.kind !== PayRateKind.BATCH_NORMALIZATION_RULES &&
    props.payRateType.kind !== PayRateKind.WEEKLY_BONUS &&
    props.payRateType.kind !== PayRateKind.RETURN_COC &&
    props.payRateType.kind !== PayRateKind.DELAYED_DELIVERY &&
    props.payRateType.kind !== PayRateKind.REIMBURSEMENT_PAY &&
    props.payRateType.kind !== PayRateKind.HAZARD_PAY &&
    props.payRateType.kind !== PayRateKind.TOBACCO_BONUS &&
    props.payRateType.kind !== PayRateKind.TIER2_DELIVERIES &&
    props.payRateType.kind !== PayRateKind.ALCOHOL_PAY &&
    props.payRateType.kind !== PayRateKind.ORDER_ATTRIBUTE &&
    props.payRateType.kind !== PayRateKind.TRIP_ATTRIBUTE &&
    props.payRateType.kind !== PayRateKind.BOOST_PAY
      ? [PayRateStatus.FUTURE]
      : [PayRateStatus.FUTURE, PayRateStatus.ACTIVE];

  return (
    <Box component='div' className={classes.outerContainer}>
      <Typography
        className={multiClass([sharedClasses.h5, classes.title])}
        data-testid={payRateTableHeaderTestId}
      >
        {props.payRateType.name}
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  Start Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.columnLabel}>
                  End Date
                </Typography>
              </TableCell>
              <TableCell>Rate Type</TableCell>
              {props.payRateType.fields.map((field) => (
                <TableCell key={field.name}>
                  <Typography className={classes.columnLabel}>
                    {field.name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row) => {
                const timeZone =
                  getTimeZoneByLocationId(
                    locations,
                    parseInt(row.locationID, 10),
                  ) || 'UTC';

                const isEditable =
                  editableStatusList.includes(row.status) && props.editable;

                return (
                  <TableRow
                    key={row.id}
                    onClick={isEditable ? () => handleClickRow(row) : undefined}
                    className={isEditable ? classes.editableRow : undefined}
                  >
                    <TableCell>
                      <StringField
                        value={formatDateTime(row.startAt, timeZone)}
                        disabled={row.status === PayRateStatus.EXPIRED}
                      />
                    </TableCell>
                    <TableCell>
                      <StringField
                        value={
                          row.endAt.time
                            ? formatDateTime(row.endAt.time, timeZone)
                            : '-'
                        }
                        disabled={row.status === PayRateStatus.EXPIRED}
                      />
                    </TableCell>
                    <TableCell>
                      <PayRateTypeChip
                        type={props.payRateType}
                        disabled={row.status === PayRateStatus.EXPIRED}
                      />
                    </TableCell>
                    {props.payRateType.fields.map((field) => (
                      <TableCell key={field.name}>
                        {field.extractor(row, locations)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.rows.length}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[4, 6, 8, 10]}
          component={'div'}
        />
      </TableContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeChip: {
      backgroundColor: sharedColors.blue1,
      color: sharedColors.blue6,
    },
    typeChipText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
    },
    title: {
      color: sharedColors.black,
    },
    tableContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
    },
    tableHeader: {
      backgroundColor: sharedColors.gray2,
    },
    columnLabel: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
    },
    editableRow: {
      cursor: 'pointer',
    },
  }),
);

export default PayRateTable;
