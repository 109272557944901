import { Location } from './Location';

type ResponseParserFn = (payRate: PayRate, responseData: any) => PayRate;
type FieldExtractorFn = (
  payRate: PayRate,
  locations: Location[],
) => JSX.Element;
type InputFormGeneratorFn = (
  currentState: PayRate,
  setter: (newPayRate: PayRate) => void,
  isDisabled: boolean,
) => JSX.Element | null;
type OutputTransformerFn = (
  payRateToTransform: any,
  timeZone: string | null,
  isEditing: boolean,
) => any;
type OverviewGeneratorFn = (payRate: PayRate) => JSX.Element;
type ValidatorFn = (payRate: PayRate) => void;

export interface PayRateField {
  name: string;
  responseParser: ResponseParserFn;
  extractor: FieldExtractorFn;
}

export enum VariableBasePayCheck {
  NONE = 'NONE',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum PayRateKind {
  ALL_KINDS = '__ALL_KINDS__',
  WEEKLY_BONUS = 'WEEKLY_BONUS',
  FIRST_SHIFT_BONUS = 'FIRST_SHIFT_BONUS',
  BOOST_PAY = 'BOOST_PAY',
  OVERTIME_PAY = 'OVERTIME_PAY',
  TIER2_DELIVERIES = 'TIER2_DELIVERIES',
  HAZARD_PAY = 'HAZARD_PAY',
  TOBACCO_BONUS = 'TOBACCO_BONUS',
  ALCOHOL_PAY = 'ALCOHOL_PAY',
  WAIT_PAY = 'WAIT_PAY',
  TRIP_FARE_LIMITS = 'TRIP_FARE_LIMITS',
  BATCH_NORMALIZATION_RULES = 'BATCH_NORMALIZATION_RULES',
  COMMISSION = 'COMMISSION',
  SUBSIDY = 'SUBSIDY',
  ENGAGED = 'ENGAGED',
  RETURN_COC = 'RETURN_COC',
  REIMBURSEMENT_PAY = 'REIMBURSEMENT_PAY',
  DELAYED_DELIVERY = 'DELAYED_DELIVERY',
  ORDER_ATTRIBUTE = 'ORDER_ATTRIBUTE',
  TRIP_ATTRIBUTE = 'TRIP_ATTRIBUTE',
}

export enum ScheduledRateType {
  WAIT_PAY = 'subsidy',
}

export enum DppAdjustmentRuleType {
  BATCH_NORMALIZATION_RULES = 'batch-normalization',
}

export enum DelayedDeliveryType {
  FAM_20 = 'fam_20',
  STANDARD = 'standard',
  STARBUCKS = 'starbucks',
}

export enum VendorType {
  GOPUFF = 'gopuff',
  SHOPIFY = 'shopify',
  DOORDASH = 'doordash',
  UBER_EATS = 'uber_eats',
}

export enum PriorityType {
  FAM20 = 'fam20',
  PRIORITY = 'priority',
}

export interface DelayedDeliveryIncentive {
  delayMinutes: number;
  offerDenials: number;
  amount: number;
}

export enum GenericPayRateType {
  COMMISSION = 'commission',
  SUBSIDY = 'subsidy',
  ENGAGED = 'engaged_pay',
  BOOST_PAY = 'special_event',
  TIER2_DELIVERIES = 'tier2_delivery',
  HAZARD_PAY = 'hazard_pay',
  TOBACCO_BONUS = 'tobacco_pay',
  ALCOHOL_PAY = 'alcohol_pay',
  FIRST_SHIFT_BONUS = 'first_shift_bonus',
  OVERTIME_PAY = 'overtime_pay',
  WEEKLY_BONUS = 'weekly_bonus',
  RETURN_COC = 'return_coc',
  REIMBURSEMENT_PAY = 'reimbursement',
  ORDER_ATTRIBUTE = 'order_attribute',
  TRIP_ATTRIBUTE = 'trip_attribute',
  DELAYED_DELIVERY = 'delayed_delivery',
}

export interface PayRateType {
  kind: PayRateKind;
  name: string;
  shortName: string;
  description: string;
  fields: PayRateField[];
  disabled: boolean;
  template: PayRate;
  apiUrl?: string;
  responseKey: string;
  isNullableStatusParam: boolean;
  isInstantExpireAllowed: boolean;
  isCreationAllowed: boolean;
  mocks: PayRate[];
  inputFormGenerator: InputFormGeneratorFn;
  outputTransformer: OutputTransformerFn;
  overViewGenerator: OverviewGeneratorFn;
  shouldRenderComparison: boolean;
  shouldRenderFinalConfirmDialog: boolean;
  validator: ValidatorFn;
  editPermissions: number;
  variableBasePayCheck: VariableBasePayCheck;
}

export interface NullableDate {
  time?: Date;
  valid: boolean;
}

export interface PayRate {
  kind: PayRateKind;
  id: number;
  locationID: string;
  startAt: Date;
  endAt: NullableDate;
  status: PayRateStatus;
  lastUpdatedBy: string;
}

export interface WeeklyBonus extends PayRate {
  tier1Orders?: number;
  tier1Amount?: number;
  tier2Orders?: number;
  tier2Amount?: number;
  tier3Orders?: number;
  tier3Amount?: number;
  tier4Orders?: number;
  tier4Amount?: number;
  legacyID?: string;
}

export interface FirstShiftBonus extends PayRate {
  amount?: number;
  minOrders?: number;
  minHours?: number;
  legacyID?: string;
}

export interface DelayedDeliveryPay extends PayRate {
  standardAmount: number;
  standardDelayMinutes: number;
  standardOfferDenials: number;
  fam20Amount: number;
  fam20DelayMinutes: number;
  starbucksAmount: number;
  starbucksDelayMinutes: number;
}

export interface OrderAttributePay extends PayRate {
  nonPriorityAmount: number;
  priorityAmount: number;
  fam20PriorityAmount: number;
  newbieAmount: number;
  gopuffAmount: number;
  doordashAmount: number;
  uberEatsAmount: number;
  shopifyAmount: number;
  starbucksAmount: number;
  customerID: ListBasedPayRate[];
  h3s: H3PayRate[];
  handshakeAmount: number;
}

export interface ListBasedPayRate {
  amount: number;
  list: string[];
}

export interface TripIncentive extends PayRate {
  incentivePerDistanceInMiles: ThresholdBasedPayRate[];
  incentivePerDurationInMinutes: ThresholdBasedPayRate[];
}

export interface H3PayRate {
  amount: number;
  resolution: number;
  hexID: string;
}

export interface BoostPay extends PayRate {
  amount: number;
  name: string;
  startTime: Date;
  endTime: Date;
  legacyID?: string;
}

export interface OvertimePay extends PayRate {
  eightHoursChecked?: boolean;
  twelveHoursChecked?: boolean;
  fortyHoursChecked?: boolean;
  eightHoursMultiplier?: number;
  twelveHoursMultiplier?: number;
  fortyHoursMultiplier?: number;
  legacyID?: string;
}

export interface ReturnCoc extends PayRate {
  pay: ReturnCocPaySetting[];
}

export interface ReturnCocPaySetting {
  dropOffs: number;
  amount: number;
}

export enum ReimbursementRate {
  PER_HOUR = 'per_hour',
  PER_TRIP = 'per_trip',
  PER_ORDER = 'per_order',
}

export interface ReimbursementPay extends PayRate {
  amount: number;
  rate: ReimbursementRate;
  isTaxable: boolean;
  name: string;
}

export interface PayRateSchedule extends PayRate {
  spans: PayRateScheduleSpan[];
}

export interface PayRateScheduleSpan {
  rate?: number;
  dayStart: number;
  dayEnd: number;
  timeStart: number;
  timeEnd: number;
}

export interface PayRateScheduleSpanRange {
  name: string;
  timeStart: number;
  timeEnd: number;
}

export interface PayRateScheduleSpanRequest {
  rate: number;
  day_start: number;
  day_end: number;
  time_start: number;
  time_end: number;
}

export interface GenericPayRateMetadata {
  legacyID?: string;
}

export interface GenericPayRate extends PayRate {
  amount: number;
  featureFlags: string[];
  metadata: GenericPayRateMetadata;
}

export interface PercentagePerBatchSize {
  batchSize: number;
  percentage: number;
}

export interface ThresholdBasedPayRate {
  amount: number;
  threshold: number;
}

export interface BatchNormalizationRules extends PayRate {
  percentagesPerBatchSizes: PercentagePerBatchSize[];
}

export enum NewPayRateStepKind {
  SELECT_RATE_TYPE = 'SELECT_RATE_TYPE',
  SET_RULES = 'SET_RULES',
  CONFIRM = 'CONFIRM',
}

export interface NewPayRateStep {
  kind: NewPayRateStepKind;
  title: string;
  label: string;
  content: JSX.Element;
}

export interface PayRateFormProps {
  currentState: PayRate;
  setter: (payRate: PayRate) => void;
  isDisabled: boolean;
}

export interface GenericPayRateFormProps extends PayRateFormProps {
  genericPayType: GenericPayRateType;
}

export interface PayRateScheduleFormProps extends PayRateFormProps {
  title: string;
}

export interface PayRateOverviewProps {
  payRate: PayRate;
}

export interface GenericPayRateOverviewProps extends PayRateOverviewProps {
  amountUnit?: string;
  genericPayType: GenericPayRateType;
}

export enum PayRateStatus {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
}

export interface PayRateToEdit {
  payRateType: PayRateType;
  payRate: PayRate;
}

export interface RateMultiplier {
  checkboxLabel: JSX.Element;
  fieldName: string;
  checkboxFieldName: keyof OvertimePay;
}

export interface BoostPayTimeInterval {
  value: number;
  hours: number;
  minutes: number;
  label: string;
}

export interface ActivePayRates {
  rates: PayRate[];
  locations: string[];
}
