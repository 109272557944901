import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { sharedColors } from '../../../utilities/Styles';
import {
  H3PayRate,
  ListBasedPayRate,
  OrderAttributePay,
  PayRate,
  PayRateFormProps,
} from '../../../interfaces/PayRate';
import { isNumber } from '../../../utilities/Misc';
import { generateNumberInput } from '../../../utilities/PayRates';

interface PaySettingCustomerIDInputProps {
  values: string[];
  onChange: (newValue: string[]) => void;
  disabled: boolean;
}

const PaySettingCustomerIDInput = (props: PaySettingCustomerIDInputProps) => {
  const [textValues, setTextValues] = useState(props.values.join(','));

  const handleTextChange = (e: any) => {
    setTextValues(e.target.value);
  };

  useEffect(() => {
    setTextValues(props.values.join(','));
  }, [props.values]);

  const splitTextValues = textValues.split(/[\s,]+|\r?\n/);
  const filteredSplitTextValues = splitTextValues.filter(
    (value) => value !== '',
  );

  return (
    <Box component='div'>
      <TextField
        label={`Customer IDs (${filteredSplitTextValues.length.toString()})`}
        multiline
        value={textValues}
        onChange={handleTextChange}
        maxRows={5}
        onBlur={() => props.onChange(filteredSplitTextValues)}
        fullWidth
        size='small'
        disabled={props.disabled}
      />
    </Box>
  );
};

interface PaySettingStringInputProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled: boolean;
  label: string;
}

const PaySettingStringInput = (props: PaySettingStringInputProps) => {
  const handleTextChange = (e: any) => {
    props.onChange(e.target.value.trim());
  };

  return (
    <Box component='div'>
      <TextField
        label={props.label}
        value={props.value}
        onChange={handleTextChange}
        fullWidth
        size='small'
        disabled={props.disabled}
      />
    </Box>
  );
};

interface PaySettingAmountInputProps {
  value: number;
  onChange: (newValue: number) => void;
  disabled: boolean;
  title: string;
  isFloat: boolean;
}

const PaySettingAmountInput = (props: PaySettingAmountInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const handleChange = (e: any) => {
    props.onChange(
      props.isFloat ? parseFloat(e.target.value) : parseInt(e.target.value, 10),
    );
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string => {
    if (isFocused) {
      return plainTextValue;
    }
    if (isNumber(props.value)) {
      return props.isFloat ? props.value.toFixed(2) : props.value.toFixed(0);
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue());
  };

  return (
    <TextField
      label={props.title}
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue()}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={handleFocus}
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
      name={props.title}
      disabled={props.disabled}
    />
  );
};

interface PaySettingRowProps {
  currentSetting: ListBasedPayRate;
  onChange: (newSetting: ListBasedPayRate) => void;
  onDelete?: () => void;
  disabled: boolean;
}

const PaySettingRow = (props: PaySettingRowProps) => {
  const classes = useStyles();

  const handleChangeAmount = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      amount: newAmount,
    });
  };

  const handleChangeList = (newAmount: string[]) => {
    props.onChange({
      ...props.currentSetting,
      list: newAmount,
    });
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={8} className={classes.gridItem}>
        <PaySettingCustomerIDInput
          values={props.currentSetting.list}
          onChange={handleChangeList}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <PaySettingAmountInput
          title={'Amount'}
          isFloat={true}
          value={props.currentSetting.amount}
          onChange={handleChangeAmount}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <IconButton
          size='small'
          color='error'
          disabled={!props.onDelete || props.disabled}
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

interface H3RowProps {
  currentSetting: H3PayRate;
  onChange: (newSetting: H3PayRate) => void;
  onDelete?: () => void;
  disabled: boolean;
}

const H3Row = (props: H3RowProps) => {
  const classes = useStyles();

  const handleChangeAmount = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      amount: newAmount,
    });
  };

  const handleChangeResolution = (newAmount: number) => {
    props.onChange({
      ...props.currentSetting,
      resolution: newAmount,
    });
  };

  const handleChangeHexID = (newAmount: string) => {
    props.onChange({
      ...props.currentSetting,
      hexID: newAmount,
    });
  };

  return (
    <Grid container spacing={1} className={classes.grid}>
      <Grid item xs={6} className={classes.gridItem}>
        <PaySettingStringInput
          label={'H3 Hex ID'}
          value={props.currentSetting.hexID}
          onChange={handleChangeHexID}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <PaySettingAmountInput
          title={'Resolution'}
          isFloat={false}
          value={props.currentSetting.resolution}
          onChange={handleChangeResolution}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <PaySettingAmountInput
          title={'Amount'}
          isFloat={true}
          value={props.currentSetting.amount}
          onChange={handleChangeAmount}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <IconButton
          size='small'
          color='error'
          disabled={!props.onDelete || props.disabled}
          onClick={props.onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const OrderAttributeForm = (props: PayRateFormProps) => {
  const classes = useStyles();

  const incentive = cloneDeep(props.currentState as OrderAttributePay);

  if (incentive.customerID.length === 0) {
    incentive.customerID = [{ list: [], amount: 0 }];
  }

  if (incentive.h3s.length === 0) {
    incentive.h3s = [{ hexID: '', amount: 0, resolution: 0 }];
  }

  const handleChangeCustomerTier = (
    newSetting: ListBasedPayRate,
    i: number,
  ) => {
    const newTiers = cloneDeep(incentive.customerID);
    newTiers[i] = cloneDeep(newSetting);
    props.setter({
      ...incentive,
      customerID: newTiers,
    } as OrderAttributePay as PayRate);
  };

  const handleDeleteCustomerTier = (i: number) => {
    const newTiers = cloneDeep(incentive.customerID);
    newTiers.splice(i, 1);
    props.setter({
      ...incentive,
      customerID: newTiers,
    } as OrderAttributePay as PayRate);
  };

  const handleNewCustomerTier = () => {
    props.setter({
      ...incentive,
      customerID: [...incentive.customerID, { list: [], amount: 0 }],
    } as OrderAttributePay as PayRate);
  };

  const handleChangeH3sTier = (newSetting: H3PayRate, i: number) => {
    const newTiers = cloneDeep(incentive.h3s);
    newTiers[i] = cloneDeep(newSetting);
    props.setter({
      ...incentive,
      h3s: newTiers,
    } as OrderAttributePay as PayRate);
  };

  const handleDeleteH3sTier = (i: number) => {
    const newTiers = cloneDeep(incentive.h3s);
    newTiers.splice(i, 1);
    props.setter({
      ...incentive,
      h3s: newTiers,
    } as OrderAttributePay as PayRate);
  };

  const handleNewH3sTier = () => {
    props.setter({
      ...incentive,
      h3s: [...incentive.h3s, { hexID: '', amount: 0, resolution: 0 }],
    } as OrderAttributePay as PayRate);
  };

  return (
    <Box component='div' className={classes.formWrapper}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'gopuffAmount',
            props.isDisabled,
            'Gopuff/Bevmo',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'shopifyAmount',
            props.isDisabled,
            'Shopify',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'doordashAmount',
            props.isDisabled,
            'Doordash',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'uberEatsAmount',
            props.isDisabled,
            'Uber Eats',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'starbucksAmount',
            props.isDisabled,
            'Starbucks',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'newbieAmount',
            props.isDisabled,
            'Newbie',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'priorityAmount',
            props.isDisabled,
            'Priority',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'fam20PriorityAmount',
            props.isDisabled,
            'Fam20 Priority',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
        <Grid item xs={4}>
          {generateNumberInput<OrderAttributePay>(
            false,
            true,
            props.currentState,
            props.setter,
            'handshakeAmount',
            props.isDisabled,
            'Handshake',
            undefined,
            '',
            undefined,
            false,
            false,
            true,
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box component='div' className={classes.tiersContainer}>
        {incentive.customerID.map((paySetting, i) => {
          return (
            <PaySettingRow
              currentSetting={paySetting}
              disabled={props.isDisabled}
              onChange={(newSetting) => handleChangeCustomerTier(newSetting, i)}
              onDelete={
                incentive.customerID.length > 1
                  ? () => handleDeleteCustomerTier(i)
                  : undefined
              }
            />
          );
        })}
        {
          <Button
            disabled={props.isDisabled}
            onClick={handleNewCustomerTier}
            className={classes.newTierButton}
          >
            + New Customer ID Rule
          </Button>
        }
      </Box>
      <Divider />
      <Box component='div' className={classes.tiersContainer}>
        {incentive.h3s.map((paySetting, i) => {
          return (
            <H3Row
              currentSetting={paySetting}
              disabled={props.isDisabled}
              onChange={(newSetting) => handleChangeH3sTier(newSetting, i)}
              onDelete={
                incentive.h3s.length > 1
                  ? () => handleDeleteH3sTier(i)
                  : undefined
              }
            />
          );
        })}
        {
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              disabled={props.isDisabled}
              onClick={handleNewH3sTier}
              className={classes.newTierButton}
            >
              + New H3 Hex ID Rule
            </Button>
            <Typography className={classes.hexDisclaimer}>
              Hex incentive is applied once per trip.
            </Typography>
          </div>
        }
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    numberField: {
      width: 160,
      marginRight: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
    },
    gridItem: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    dropOffsDropdown: {
      display: 'flex',
      flexGrow: 1,
    },
    dropdownLabel: {
      backgroundColor: sharedColors.white,
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    tiersContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(1),
    },
    newTierButton: {
      textTransform: 'none',
      width: 'fit-content',
    },
    body: {
      marginLeft: theme.spacing(-3.5),
      marginRight: theme.spacing(-3.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: sharedColors.gray1,
      display: 'flex',
      flexDirection: 'column',
    },
    textField: {
      width: 350,
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(5),
    },
    hexDisclaimer: {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      letterSpacing: '0.4px',
      marginTop: '12px',
    },
  }),
);
